<template>
  <div
    class="share-link">
    <v-tooltip
      :disabled="!canWorksWithShareProjectLink.allowed"
      top>
      <template #activator="{ on }">
        <app-action-btn
          icon-class="v-icon__dialog-icon-small mr-2"
          :icon-src="require('@/assets/icons/share-action-icon.svg')"
          wrapper-class="medium-p d-flex"
          show-tooltip
          top
          alt="share"
          :access="canWorksWithShareProjectLink"
          v-on="on"
          @click="copyProjectLink">
          <template #iconWrapper>
            <v-icon>
              mdi-open-in-new
            </v-icon>
          </template>
          Send a Quick View Link
        </app-action-btn>
      </template>
      <span>
        Everything that is visible for Viewer role will be accessible using Quick View Link.
        If you want to adjust some permissions please go to the view settings
        <v-icon
          size="15"
          color="white">
          mdi-settings
        </v-icon>
        → Share tab
      </span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';
import { copyToClipBoard } from '@/utils';
import ProjectsApi from '@/services/graphql/projects';
export default {
  name: 'ProjectDetailsSharedLink',
  props: {
    projectId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isSharedLink: 'ProjectDetails/isSharedLink',
      canShareProject: 'UserRoles/canShareProjectLinkWithNonMembers',
    }),
    ...mapState(['role']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    canWorksWithShareProjectLink() {
      return this.canShareProject(this.role);
    },
  },
  methods: {
    ...mapMutations({
      setDetailDataOfProject: 'ProjectDetails/setDetailDataOfProject',
    }),
    async copyProjectLink() {
      const { allowed } = this.canWorksWithShareProjectLink ?? {
      };
      if (!allowed) return;
      this.projectSharedLink(true);
    },
    async projectSharedLink(isShared) {
      const { isSharedLink, projectId } = this;
      try {
        if (isSharedLink) {
          return;
        }
        const workspaceId = this.activeWorkspaceId;
        const { data } = await ProjectsApi.projectSharedLink({
          projectId,
          isShared,
          workspaceId,
        });
        this.setDetailDataOfProject(data.projectSharedLink);
      } catch (err) {
        console.log('projectSharedLink', err);
      } finally {
        copyToClipBoard(window.location.href.replace('project', 'shared-project'), 'Link copied for sending');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.share-link {
  position: relative;
  p {
    align-items: center;
  }
}
</style>
